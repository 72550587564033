import React from "react";
import { Slide } from 'react-reveal';

function Testimonial(props){
    return(
        <div className="h-full">
            <div className="w-full px-8 py-4 bg-white rounded-lg shadow-xl">
                <div className="flex justify-center -mt-10 text-blue-custome">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-16 w-16" viewBox="0 0 16 16">
                        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"/>
                    </svg>
                </div>
                {props.children}
            </div>

            <div className="px-2 flex justify-start">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-12 h-12 m-0 transform rotate-90 -mt-4 text-white" viewBox="0 0 16 16">
                    <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                </svg>
            </div>

            <div className="flex items-center">
                <img className="bg-white object-cover w-16 h-16 rounded-full shadow-lg" alt="Testimonial avatar" src={props.logo} />
                <div className="ml-4 flex flex-col items-start justify-center ">
                    <p className="text-gray-600">{props.name}</p>
                    <h2 className="font-semibold text-2xl text-gray-800">{props.company}</h2>
                </div>
                
            </div>
        </div>
    )
}
export default Testimonial;


export function TestimonialCard(props){
    return(
        <Slide bottom>
            <div className="bg-white shadow-lg rounded-lg px-6 pb-6 flex flex-col justify-between items-start">                 
                <div className="flex flex-col text-gray-200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="-ml-2 h-14 w-14" viewBox="0 0 16 16">
                        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"/>
                    </svg>
                    <p className="text-gray-600">{props.children}</p>
                </div>
                
                <div className="w-full flex items-center mt-6 pt-6 border-t border-gray-100">
                    <div className="shadow-lg border border-gray-100 rounded-full w-16 h-16 overflow-hidden">
                        <img  classname="w-full" src={props.logo} alt=""/>
                    </div>
                    <div className="ml-6 flex flex-col items-start justify-center">
                        <p className="text-gray-500">{props.name}</p>
                        <h2 className="text-lg font-semibold text-gray-800">{props.company}</h2>
                    </div>
                    
                </div>
            </div>
        </Slide>
    );
}